import {inject, injectable} from "inversify";
import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IHttpClientService} from "data/services/http";

export interface ISponsorProvider {
	sendSponsorFlag: () => Promise<AxiosResponse>;
}

@injectable()
export class SponsorProvider implements ISponsorProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}
	sendSponsorFlag() {
		return this._http.get("predictor/sponsor");
	}
}
