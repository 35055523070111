import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface IMenuStore {
	get isOpen(): boolean;

	openMenu(): void;

	closeMenu(): void;
}

@injectable()
export class MenuStore implements IMenuStore {
	@observable private _isOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._isOpen;
	}

	@action
	public closeMenu(): void {
		this._isOpen = false;
	}

	@action
	public openMenu(): void {
		this._isOpen = true;
	}
}
