import React, {Fragment, PropsWithChildren} from "react";
import {PagePreloader} from "views/components/preloader";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import type {ISessionController} from "views/components/session/session.controller";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";

export const Session: React.FC<PropsWithChildren> = observer(({children}) => {
	const location = useLocation();
	const navigate = useNavigate();

	const mmid = new URLSearchParams(location.search).get("mmid");
	const {isSessionChecked} = useViewController<ISessionController>(Bindings.SessionController, {
		mmid,
		navigate,
	});

	return isSessionChecked ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
