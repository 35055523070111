import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import Cookies from "js-cookie";
import {Bindings} from "data/constants/bindings";
import type {ISponsorStore} from "data/stores/sponsor/sponsor.store";
import {observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface ISecretGateController extends ViewController {
	handleSecretClick: () => void;

	get isSecretPassed(): boolean;

	get i18n(): ILocalizationStore;
}

@injectable()
export class SecretGateController implements ISecretGateController {
	@observable private _count = 0;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SponsorStore) private _sponsorStore: ISponsorStore
	) {}

	get isSecretPassed() {
		const isSecretEnabledSettings = Boolean(this._sponsorStore.settings?.offSeason);
		const isSecretEnabledEnv = Boolean(
			JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
		);

		if (isSecretEnabledEnv || isSecretEnabledSettings) {
			return Boolean(JSON.parse(Cookies.get("isSecretPassed") || "false"));
		}

		return true;
	}

	handleSecretClick = () => {
		if (this.isSecretPassed) {
			return;
		}

		this._count += 1;

		if (this._count >= 10) {
			Cookies.set("isSecretPassed", "true");
			window.location.reload();
		}
	};
}
