import {createTheme} from "@mui/material/styles";
import {buttonsTheme} from "assets/theming/buttons.theme";
import {typographyTheme} from "assets/theming/typography.theme";
import {paletteTheme} from "assets/theming/palette.theme";
import {checkboxTheme} from "assets/theming/checkbox.theme";
import {tabsTheme} from "assets/theming/tabs.theme";

export const theme = createTheme(
	paletteTheme,
	buttonsTheme,
	checkboxTheme,
	tabsTheme,
	typographyTheme
);
