import {retryFailLoad} from "data/utils";
import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

// const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComingSoon = lazy(
	retryFailLoad(() => import("views/pages/coming_soon/coming_soon.component"))
);

export const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route path="*" element={<ComingSoon />} />
		{/*<Route path="*" element={<NotFound />} />*/}
	</Routes>
);

export default SecretGateRoutes;
