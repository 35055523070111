import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {ILeaderboardParams, ILeaderboardResponse} from "data/types/api";

export type TLeaderboardResponse = IApiResponse<ILeaderboardResponse>;

export interface ILeaderboardApiProvider {
	overallRankings: (params: ILeaderboardParams) => Promise<AxiosResponse<TLeaderboardResponse>>;
}

@injectable()
export class LeaderboardApiProvider implements ILeaderboardApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	overallRankings(params: ILeaderboardParams) {
		return this._http.get<TLeaderboardResponse>("predictor/ranking/overall", params);
	}
}
