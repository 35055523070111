import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {ILeaderboardParams, ILeaderboardRank, ILeaderboardResponse} from "data/types/api";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardApiProvider} from "data/providers/api/leaderboard.api.provider";
import {SortOrder} from "data/enums";

export interface ILeaderboardStore {
	get isLoading(): boolean;

	get rankings(): ILeaderboardRank[];

	get leaderboardUser(): ILeaderboardRank | null;

	get hasNextPage(): boolean;

	get orderBy(): SortOrder;

	set orderBy(value: SortOrder);

	loadMoreLeaderboard(): Promise<void>;

	fetchLeaderboards(page?: number): Promise<void>;
}

const RANKINGS_LIMIT = 10;

@injectable()
export class LeaderboardStore implements ILeaderboardStore {
	@observable private _orderBy: SortOrder = SortOrder.DESC;
	@observable private _page = 0;
	@observable private _leaderboardData: ILeaderboardResponse = {
		rankings: [],
		user: null,
		nextPage: false,
	};
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.LeaderboardApiProvider) private _apiProvider: ILeaderboardApiProvider
	) {
		makeAutoObservable(this);
	}

	public get orderBy(): SortOrder {
		return this._orderBy;
	}

	public set orderBy(value: SortOrder) {
		this._orderBy = value;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get hasNextPage(): boolean {
		return this._leaderboardData.nextPage;
	}

	public get leaderboardUser(): ILeaderboardRank | null {
		return this._leaderboardData.user || null;
	}

	public get rankings(): ILeaderboardRank[] {
		return this._leaderboardData.rankings;
	}

	@action
	public async fetchLeaderboards(page = 1) {
		try {
			this._isLoading = true;
			this._page = page;

			if (this._page === 1) {
				this.clearRankings();
			}

			const payload: ILeaderboardParams = {
				page,
				limit: RANKINGS_LIMIT,
				dir: this._orderBy,
			};

			const {data} = await this._apiProvider.overallRankings(payload);
			runInAction(() => {
				this._leaderboardData = {
					user: data.success.user,
					rankings: this.mergeRankings(data.success.rankings),
					nextPage: data.success.nextPage,
				};
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	public loadMoreLeaderboard() {
		return this.fetchLeaderboards(this._page + 1);
	}

	@action
	public mergeRankings(rankings: ILeaderboardRank[]): ILeaderboardRank[] {
		if (this._page === 1) {
			return rankings;
		}
		return [...this.rankings, ...rankings];
	}

	@action
	public clearRankings(): void {
		this._leaderboardData = {
			rankings: [],
			nextPage: false,
			user: null,
		};
	}
}
