import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import Cookies from "js-cookie";

export interface IModalGameLogicChangesController extends ViewController {
	close: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;
}

@injectable()
export class ModalGameLogicChangesController implements IModalGameLogicChangesController {
	@observable private _isOpen: boolean = false;

	constructor(@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._isOpen;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		// const cookieValue = JSON.parse(Cookies.get("isChangesViewed") || "false") as boolean;
		// this._isOpen = !cookieValue;
	}

	public close = (): void => {
		Cookies.set("isChangesViewed", "true");
		this._isOpen = false;
	};
}
