import {ThemeOptions} from "@mui/material/styles";

export const tabsTheme: ThemeOptions = {
	components: {
		MuiTab: {
			styleOverrides: {
				root: {
					padding: "0 8px",
					background: "#f3f1ee",

					color: "var(--text-default)",
					fontSize: "13px",
					fontWeight: 420,
					lineHeight: "130%",
					textTransform: "uppercase",
					borderBottom: "4px solid #FFFFFF",
				},
			},
		},
	},
};
