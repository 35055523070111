import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ChangeEvent, SyntheticEvent} from "react";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {debounce, isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IMyAccountFormElement extends HTMLFormElement {
	displayName: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;

	get i18n(): ILocalizationStore;

	get isFormDisabled(): boolean;

	get error(): Record<string, string> | null;

	get displayName(): string;

	get user(): IUser;

	get isUpdateDisabled(): boolean;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isFormChanged = false;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get displayName() {
		return this._userStore.tempName;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	public dispose() {
		this._userStore.clearTempName();
	}

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._userStore.tempName = event.target.value.replace("@", "");
		this.checkUsername();
	};

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {displayName, isNotificationsEnabled} = event.currentTarget;

		const validateList = [
			{field: displayName, error: "Please provide your display name", place: "username"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			displayName: displayName.value,
			isNotificationsEnabled: isNotificationsEnabled.checked,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() => {
				runInAction(() => {
					this._isFormChanged = false;
				});
				this._userStore.clearTempName();
			})
			.catch(this.onError);
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	public checkUsername = debounce(() => {
		this.handleClearErrorOnChange();
		const nameLength = this.displayName.length;
		if (nameLength < 6 || nameLength > 20) {
			this._errorPlace = "displayName";
			this._errorMsg = this.i18n.t("username.error.invalid");
			return;
		}

		this._userStore
			.checkUsername(this._userStore.tempName)
			.catch((error) => this.onError(error as AxiosError<IApiResponse>));
	}, 1000);
}
