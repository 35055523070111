import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {RoundStatus} from "data/enums";
import {first, last} from "lodash";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IMatch} from "data/types/entities";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IRound {
	endAt: string;
	id: number;
	matches: IMatch[];
	name: string;
	startAt: string;
	status: RoundStatus;
}

export interface IRoundsStore {
	get getIsLoading(): boolean;

	get list(): IRound[];

	get availableToNavigateRounds(): IRound[];

	get scheduleRounds(): IRound[];

	get currentRound(): IRound | undefined;

	get scoreRound(): IRound | null;

	get selectedRound(): IRound | undefined;

	selectRoundById(roundId: number): void;

	getMatchById(matchId: number): IMatch | undefined;

	fetchRounds(): Promise<void>;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;
	@observable private _selectedRound: IRound | undefined;
	@observable private _list: IRound[] = [];

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	public get selectedRound(): IRound | undefined {
		return this._selectedRound;
	}

	get list() {
		return this._list;
	}

	get scheduleRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Complete);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentRound() {
		return (
			this.selectedRound || this.activeRound || first(this.scheduleRounds) || last(this.list)
		);
	}

	get scoreRound() {
		return this.activeRound || last(this.completedRounds) || null;
	}

	public get availableToNavigateRounds(): IRound[] {
		const complete = this.list.filter((round) => round.status === RoundStatus.Complete);
		const live = this.list.find((round) => round.status === RoundStatus.Live);
		const schedule = this.list.find((round) => round.status === RoundStatus.Scheduled);

		if (live) {
			return [...complete, live];
		}

		if (schedule) {
			return [...complete, schedule];
		}
		return complete;
	}

	private get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Live);
	}

	getMatchById(matchId: number) {
		const matches = this.currentRound?.matches || [];
		return matches.find((e) => e.id === matchId);
	}

	@action
	async fetchRounds() {
		try {
			const {data} = await this._jsonProvider.rounds();

			if (!Array.isArray(data)) {
				throw new Error("Invalid rounds response");
			}

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			this._modalsStore.showError({
				title: "modal.error.title",
				message: "common.error.rounds",
				translate: true,
			});
		}
	}

	@action
	public selectRoundById(roundId: number): void {
		const round = this.list.find((round) => round.id === roundId);
		if (!round) {
			return;
		}
		this._selectedRound = round;
	}
}
