import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IModalLeaveController extends ViewController {
	leave: () => void;
	back: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;
}

@injectable()
export class ModalLeaveController implements IModalLeaveController {
	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.ROUND_LEAVE;
	}

	private get roundId(): number | undefined {
		const payload = this._modalsStore.modalContent as IModalPayload<number>;
		return payload.data;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public back = (): void => {
		this.close();
	};

	public leave = (): void => {
		this._predictionsStore.clearPredictions();
		this.navigateToRound();
		this.close();
	};

	protected close(): void {
		this._modalsStore.hideModal();
	}

	protected navigateToRound() {
		if (!this.roundId) {
			return;
		}
		this._roundsStore.selectRoundById(this.roundId);
	}
}
