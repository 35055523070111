import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface ISquad {
	id: number;
	name: string;
	abbreviation: string;
	logo: string;
	loses: number;
	wins: number;
}

export interface ISquadsStore {
	get getIsLoading(): boolean;
	get list(): ISquad[];
	getSquadById(squadId: number): ISquad | undefined;
	fetchSquads(): Promise<void>;
}

@injectable()
export class SquadsStore implements ISquadsStore {
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _list: ISquad[] = [];

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getSquadById(squadId: number): ISquad | undefined {
		return this._list.find((squad) => squad.id === squadId);
	}

	@action
	async fetchSquads() {
		try {
			const {data} = await this._jsonProvider.squads();

			if (!Array.isArray(data)) {
				throw new Error("Invalid squads response");
			}

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			this._modalsStore.showError({
				title: "modal.error.title",
				message: "common.error.squads",
				translate: true,
			});
		}
	}
}
