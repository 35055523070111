import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameBarData, IGameBarStore} from "data/stores/game_bar/game_bar.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IGameBarController extends ViewController {
	get i18n(): ILocalizationStore;

	get isLoading(): boolean;

	get overallPoints(): number | null;

	get roundPoints(): number | null;
}

@injectable()
export class GameBarController implements IGameBarController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	public get isLoading(): boolean {
		return this._gameBarStore.isLoading;
	}

	public get overallPoints() {
		return this.gameBarData.overallPoints;
	}

	public get roundPoints() {
		return this.gameBarData.roundPoints;
	}

	protected get gameBarData(): IGameBarData {
		return this._gameBarStore.gameBarData;
	}

	protected get roundId(): number | undefined {
		return this._roundsStore.currentRound?.id;
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.fetchGameBar();

		const subscription = reaction(
			() => [this.roundId],
			() => this.fetchGameBar()
		);
		this._subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	protected fetchGameBar() {
		if (!this.roundId) {
			return;
		}
		this._gameBarStore.fetchGameBar(this.roundId).catch(this._modalsStore.showErrorFromCatch);
	}
}
