import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import headerBg from "assets/img/header/header-bg.jpg";
import headerLogo from "assets/img/header/header-logo.png";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {Button} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/header/header.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {Menu} from "views/components/menu/menu.component";
import {NavLink} from "react-router-dom";

const Wrapper = styled.div`
	width: 100%;
	height: 48px;
	background: url("${headerBg}");
	background-size: cover;
	padding: 6px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-position-y: bottom;

	> a {
		display: block;
		height: 100%;

		> img {
			height: 100%;
			width: auto;
		}
	}

	> button {
		width: 24px;
		height: 24px;
		min-width: 24px;
		background: transparent;
		border: none;

		&:active,
		&:hover,
		&:focus {
			background: transparent;
		}

		color: var(--icons-default);
	}
`;

export const Header: React.FC = observer(() => {
	const {isMenuOpen, isHeaderVisible, toggleMenu} = useViewController<IHeaderController>(
		Bindings.HeaderController
	);

	if (!isHeaderVisible) {
		return null;
	}

	return (
		<React.Fragment>
			<Wrapper>
				<NavLink to="/picks">
					<img src={headerLogo} alt="" role="banner" />
				</NavLink>
				<Button onClick={toggleMenu}>
					<Exist when={!isMenuOpen}>
						<MenuIcon />
					</Exist>
					<Exist when={isMenuOpen}>
						<CloseIcon />
					</Exist>
				</Button>
			</Wrapper>
			<Menu />
		</React.Fragment>
	);
});
