import {ThemeOptions} from "@mui/material/styles";

export const checkboxTheme: ThemeOptions = {
	components: {
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontFamily: "var(--fontFamilyBase)",
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					fontFamily: "var(--fontFamilyBase)",
				},
			},
		},
	},
};
