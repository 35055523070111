import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {MatchUtil} from "data/utils/match.util";
import type {IMatchProps} from "data/types/props";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IMatch, IPrediction} from "data/types/entities";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IMatchStatusController extends ViewController<IMatchProps> {
	get i18n(): ILocalizationStore;

	get isLocked(): boolean;

	get isCorrect(): boolean;

	get isIncorrect(): boolean;
}

@injectable()
export class MatchStatusController implements IMatchStatusController {
	@observable private _matchId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	public get isCorrect(): boolean {
		if (!this.prediction) {
			return false;
		}
		return this.prediction.win === true;
	}

	public get isIncorrect(): boolean {
		if (!this.prediction) {
			return false;
		}
		return this.prediction.win === false;
	}

	public get isLocked(): boolean {
		return MatchUtil.IS_MATCH_LIVE(this.match);
	}

	protected get match(): IMatch | undefined {
		return this._roundsStore.getMatchById(this._matchId);
	}

	protected get prediction(): IPrediction | undefined {
		return this._predictionsStore.getPredictionByMatch(this._matchId) as
			| IPrediction
			| undefined;
	}

	dispose(): void {
		return;
	}

	init(param: IMatchProps): void {
		this._matchId = param.matchId;
	}

	onChange(param: IMatchProps): void {
		this._matchId = param.matchId;
	}
}
