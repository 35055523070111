import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {IMAGES_URL} from "data/constants";
import {AxiosApiErrorGeneric, IAxiosErrorMessageObject} from "data/types/api";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export const copyToClipboard = async (content: string) => {
	try {
		await navigator.clipboard.writeText(content);
		return true;
	} catch (_err) {
		return false;
	}
};

export function getClassAccordingStatement(statement: boolean, className: string) {
	return statement ? className : "";
}

export function getDashedValue<T>(value?: null | undefined | T): string | T {
	if (value === undefined || value === null) {
		return "-";
	}
	return value;
}

export function getImageFromBackend(image: string | null | undefined) {
	if (!image) {
		return "";
	}

	return `${IMAGES_URL}${image}`;
}

export function getIsIOS() {
	if (!navigator) {
		return false;
	}
	return (
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
			navigator.platform
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
}

export function getErrorMessageFromAxiosResponse(event: AxiosApiErrorGeneric) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

export function getCheckUsernameErrorObject(error: AxiosApiErrorGeneric) {
	if (!error) {
		return "";
	}

	if (error.response?.status === 400) {
		return "username.error.exists";
	}

	if (error.response?.status === 422) {
		return "username.error.invalid";
	}

	return getErrorMessageFromAxiosResponse(error);
}

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
