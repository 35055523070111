import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IAuthApiProvider,
	ILoginPayload,
	IRegisterPayload,
} from "data/providers/api/auth.api.provider";
import type {IUpdateUserPayload, IUserApiProvider} from "data/providers/api/user.api.provider";
import type {
	IForgotPasswordPayload,
	IPasswordApiProvider,
	IResetPasswordPayload,
} from "data/providers/api/password.api.provider";
import type {AxiosResponse} from "axios";

export interface IUser {
	id: number;
	email: string;
	displayName: string;
	isNotificationsEnabled: boolean;
	isReturningModalViewed: boolean;
	isRankingModalViewed: boolean;
}

export interface IUserStore {
	get user(): IUser | undefined;

	get tempName(): string;

	set tempName(value: string);

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	get isRegisterMode(): boolean;

	get mmid(): string | null;

	forgotPassword(payload: IForgotPasswordPayload): Promise<AxiosResponse<void>>;

	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse<void>>;

	register(payload: IRegisterPayload): Promise<void>;

	update(payload: IUpdateUserPayload): Promise<void>;

	deactivate(): Promise<void>;

	login(payload: ILoginPayload): Promise<void>;

	logout(): Promise<void>;

	requestUser(): Promise<void>;

	setRegisterMode(mmid: string | null): void;

	checkUsername(username: string): Promise<void>;

	clearTempName(): void;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _tempName: string = "";
	@observable private _user?: IUser = undefined;
	@observable private _wasLoggedOut = false;
	@observable private _isRegisterMode = false;
	@observable private _mmid: string | null = null;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider
	) {
		makeAutoObservable(this);
	}

	public get tempName(): string {
		return this._tempName || this.user?.displayName || "";
	}

	public set tempName(value: string) {
		this._tempName = value;
	}

	public get mmid(): string | null {
		return this._mmid;
	}

	public get isRegisterMode(): boolean {
		return this._isRegisterMode;
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	@action
	async requestUser() {
		const response = await this._userApi.user();
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async login(payload: ILoginPayload) {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: IRegisterPayload) {
		const response = await this._userApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
			this._isRegisterMode = false;
		});
	}

	@action
	async update(payload: IUpdateUserPayload) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate() {
		await this._userApi.deactivateAccount();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	forgotPassword(payload: IForgotPasswordPayload) {
		return this._passwordApi.forgotPassword(payload);
	}

	resetPassword(payload: IResetPasswordPayload) {
		return this._passwordApi.resetPassword(payload);
	}

	public async checkUsername(username: string): Promise<void> {
		try {
			await this._userApi.checkUsername({username});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public clearTempName() {
		this._tempName = this.user?.displayName || "";
	}

	@action
	setRegisterMode(mmid: string | null) {
		this._mmid = mmid;
		this._isRegisterMode = true;
	}
}
