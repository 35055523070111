import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IHeaderController extends ViewController {
	get isMenuOpen(): boolean;
	get isHeaderVisible(): boolean;
	toggleMenu: () => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public toggleMenu = (): void => {
		if (this.isMenuOpen) {
			this._menuStore.closeMenu();
		} else {
			this._menuStore.openMenu();
		}
	};

	public get isMenuOpen(): boolean {
		return this._menuStore.isOpen;
	}

	public get isHeaderVisible(): boolean {
		return !this._userStore.isRegisterMode;
	}
}
