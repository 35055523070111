import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";

import {ReactComponent as Logo} from "assets/img/common/logo.svg";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

const Wrapper = styled.div`
	background: var(--bg-white);
	width: 100%;
	padding: 12px 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;

	svg {
		min-width: 52px;
		height: auto;
	}
`;

const Copy = styled.p`
	color: var(--text-default);
	font-size: 9px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
`;

const copy = `"Jersey Mike’s Subs," "Jersey Mike’s," and "Mike’s Way" are registered trademarks of A Sub Above, LLC. "Lay's" is a registered trademark of Frito-Lay North America, Inc. "Pepsi" is a registered trademark of PepsiCo, Inc. Patents 6,384,850, 6,871,325, 6,982,733 and 8,146,077`;

export const Footer: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	return (
		<Wrapper>
			<Logo />
			<Copy>{i18n.t("footer.copy", copy)}</Copy>
		</Wrapper>
	);
});
