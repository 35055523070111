import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalPrediction, IMatch, IPrediction} from "data/types/entities";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {PredictionSide} from "data/types/general";
import {MatchUtil} from "data/utils/match.util";

interface IParams {
	squadId: number;
	matchId: number;
	side: PredictionSide;
}

export interface IPickButtonController extends ViewController<IParams> {
	onClick: () => void;

	get match(): IMatch | undefined;

	get squad(): ISquad | undefined;

	get predictionClasses(): string;

	get lockedClass(): string;

	get isLocked(): boolean;

	get score(): null | number;
}

@injectable()
export class PickButtonController implements IPickButtonController {
	@observable private _side: PredictionSide = "home";
	@observable private _squadId: number = Number.MAX_SAFE_INTEGER;
	@observable private _matchId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get match() {
		return this._roundsStore.getMatchById(this._matchId);
	}

	get squad() {
		return this._squadsStore.getSquadById(this._squadId);
	}

	public get predictionClasses(): string {
		const classNames: string[] = [];

		if (this.isCorrect) {
			classNames.push("correct");
		}

		if (this.isIncorrect) {
			classNames.push("incorrect");
		}

		if (this.prediction) {
			classNames.push("answered");
		}

		return classNames.join(" ");
	}

	public get isLocked(): boolean {
		return this.isMatchLocked || this.isLockedByAmount;
	}

	public get lockedClass(): string {
		return this.isMatchLocked ? "locked" : "";
	}

	public get score(): null | number {
		if (!this.match) {
			return null;
		}
		return this.isHome ? this.match.homeScore : this.match.awayScore;
	}

	protected get isCorrect(): boolean {
		if (!this.roundPrediction) {
			return false;
		}
		return this.roundPrediction.win === true;
	}

	protected get isIncorrect(): boolean {
		if (!this.roundPrediction) {
			return false;
		}
		return this.roundPrediction.win === false;
	}

	protected get prediction(): IPrediction | ILocalPrediction | undefined {
		return this._predictionsStore.getPredictionByMatchAndSquad(this._matchId, this._squadId);
	}

	protected get roundPrediction(): IPrediction | undefined {
		return this.prediction as IPrediction | undefined;
	}

	protected get isHome(): boolean {
		return this._side === "home";
	}

	protected get isMatchLocked(): boolean {
		return MatchUtil.IS_MATCH_LOCKED(this.match);
	}

	protected get isMaximumPredictionsAmount(): boolean {
		if (!this._predictionsStore.maximumPredictionsAmount) {
			return false;
		}
		return (
			this._predictionsStore.uniqPredictions.length >=
			this._predictionsStore.maximumPredictionsAmount
		);
	}

	protected get hasPrediction(): boolean {
		return Boolean(this._predictionsStore.getPredictionByMatch(this._matchId));
	}

	protected get isLockedByAmount(): boolean {
		return this.isMaximumPredictionsAmount && !this.hasPrediction;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this.initParams(param);
	}

	onChange(param: IParams): void {
		this.initParams(param);
	}

	public onClick = () => {
		if (this.isLocked || !this.roundPrediction) {
			return;
		}

		const {match, squad} = this.roundPrediction;

		if (match === this._matchId && squad === this._squadId) {
			this._predictionsStore.removePrediction(match, squad);
		}
	};

	@action
	protected initParams(param: IParams) {
		this._squadId = param.squadId;
		this._matchId = param.matchId;
		this._side = param.side;
	}
}
