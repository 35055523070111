import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ShareType, SocialNetwork} from "data/enums";
import {share} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import React from "react";

export interface ISharingController extends ViewController {
	share: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	popupClose: () => void;
	shareFacebook: () => void;
	shareTwitter: () => void;
	setAnchorEl: (event: React.SyntheticEvent<HTMLButtonElement> | null) => void;

	get i18n(): ILocalizationStore;

	get anchorEl(): HTMLButtonElement | null;

	get isPopoverOpen(): boolean;

	get isMobileShare(): boolean;
}

@injectable()
export class SharingController implements ISharingController {
	@observable private _anchorEl: HTMLButtonElement | null = null;
	@observable private _isPopoverOpen: boolean = false;
	@observable private _isNarrowScreen: boolean = false;

	constructor(@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	public get anchorEl(): HTMLButtonElement | null {
		return this._anchorEl;
	}

	public get isPopoverOpen(): boolean {
		return this._isPopoverOpen;
	}

	public get isMobileShare(): boolean {
		return "share" in navigator && this._isNarrowScreen;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	private get shareMessage(): string {
		return this.i18n.t(
			"common.share.text",
			"I’ve predicted my NHL winners today, start building your streak now!"
		);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	onChange(param: void): void {
		return;
	}

	public share = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		if (this.isMobileShare) {
			share({
				socialNetwork: SocialNetwork.Mobile,
				type: ShareType.General,
				message: this.shareMessage,
			});
		} else {
			this.setAnchorEl(event);
			this.openSharePopup();
		}
	};

	@action
	public popupClose = (): void => {
		this._isPopoverOpen = false;
	};

	@action
	public shareFacebook = () => {
		share({
			socialNetwork: SocialNetwork.Facebook,
			type: ShareType.General,
			message: this.shareMessage,
		});
	};

	@action
	public shareTwitter = () => {
		share({
			socialNetwork: SocialNetwork.Twitter,
			type: ShareType.General,
			message: this.shareMessage,
		});
	};

	@action
	public setAnchorEl = (event: React.SyntheticEvent<HTMLButtonElement> | null) => {
		this._anchorEl = event?.currentTarget || null;
	};

	@action
	private openSharePopup(): void {
		this._isPopoverOpen = true;
	}

	@action
	private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};
}
