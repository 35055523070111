import React from "react";
import {observer} from "mobx-react";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Modal} from "@mui/material";
import {
	ModalCloseBlock,
	ModalContent,
	ModalText,
	ModalTitle,
} from "views/components/modals/modals.common";
import logo from "assets/img/landing/logo.png";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {IModalReturnedUserController} from "views/components/modals/modal_returned_user/modal_returned_user.controller";
import {Bindings} from "data/constants/bindings";

const Logo = styled.img`
	max-height: 120px;
	height: 100%;
	width: auto;
`;

const SModalText = styled(ModalText)`
	span {
		color: var(--text-default, #253746);
		font-size: 16px;
		font-style: normal;
		font-weight: bold;
		line-height: 130%;
		text-transform: uppercase;
	}
`;

export const ModalReturnedUser: React.FC = observer(() => {
	const {i18n, isOpen, isLoading, close} = useViewController<IModalReturnedUserController>(
		Bindings.ModalReturnedUserController
	);
	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseBlock>
					<button disabled={isLoading} onClick={close}>
						<CloseIcon />
					</button>
				</ModalCloseBlock>
				<Logo src={logo} alt="loco" />
				<ModalTitle>
					{i18n.t("modal.phase_two.title", "SECOND HALF OF THE SEASON STARTS NOW")}
				</ModalTitle>
				<SModalText
					dangerouslySetInnerHTML={{
						__html: i18n.t(
							"modal.phase_two.body",
							"Pick NHL winners each day to earn points and be in a chance to “WIN TICKETS” to the STANLEY CUP FINAL!"
						),
					}}
				/>
				<Button onClick={close} disabled={isLoading}>
					{i18n.t("modal.phase_two.button_play", "PLAY")}
				</Button>
			</ModalContent>
		</Modal>
	);
});
