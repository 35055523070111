import {inject, injectable} from "inversify";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {IPredictionSaveEntity} from "data/types/entities";
import {IFetchPredictionsResponse} from "data/types/api";
import {AxiosResponse} from "axios";
import {IGameBarData} from "data/stores/game_bar/game_bar.store";

type IFetchPredictionsResponseEntity = IApiResponse<IFetchPredictionsResponse>;
type IFetchGameBarData = IApiResponse<{gameBar: IGameBarData}>;

export interface IPredictionsApiProvider {
	savePredictions(
		predictions: IPredictionSaveEntity,
		round: number
	): Promise<AxiosResponse<IFetchPredictionsResponseEntity>>;

	fetchPredictions(roundId: number): Promise<AxiosResponse<IFetchPredictionsResponseEntity>>;

	fetchGameBar(roundId: number): Promise<AxiosResponse<IFetchGameBarData>>;
}

@injectable()
export class PredictionsApiProvider implements IPredictionsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	public fetchPredictions(roundId: number) {
		return this._http.get<IFetchPredictionsResponseEntity>(
			`predictor/prediction/show/${roundId}`
		);
	}

	public savePredictions(predictions: IPredictionSaveEntity, round: number) {
		return this._http.post<IFetchPredictionsResponseEntity>(`predictor/prediction/save`, {
			round,
			predictions,
		});
	}

	public fetchGameBar(roundId: number) {
		return this._http.get<IFetchGameBarData>(`predictor/ranking/game_bar/${roundId}`);
	}
}
