import React, {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const Register = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const PickPage = lazy(retryFailLoad(() => import("views/pages/picks/picks.page")));
const LeaderboardPage = lazy(
	retryFailLoad(() => import("views/pages/leaderboard/leaderboard.page"))
);
const ForgotPassword = lazy(
	retryFailLoad(() => import("views/pages/forgot_password/forgot_password.page"))
);
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route path="/" element={<Navigate to="/picks" />} />
		<Route path="/picks" element={<PickPage />} />
		<Route element={<NotAuthOnlyRoute />}>
			<Route path="/register" element={<Register />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/leaderboard" element={<LeaderboardPage />} />
			<Route path="/my-account" element={<MyAccount />} />
		</Route>
		<Route path="help/*" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default RootRoutes;
