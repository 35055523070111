/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {PaletteColorOptions, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		alternative: PaletteColorOptions;
	}
	interface Palette extends CustomPalette {}
	interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		alternative: true;
	}
}

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "var(--fontFamilyBase)",
					fontWeight: "700",
					fontSize: "16px",
					borderRadius: "82px",
					textAlign: "center",
					boxShadow: "none",
				},
				textPrimary: {
					color: "var(--text-white)",
					background: "var(--primary-default)",
					border: "2px solid var(--primary-default)",
					"&:hover": {
						background: "var(--primary-hover)",
						borderColor: "var(--primary-hover)",
					},
					"&:active": {
						background: "var(--primary-hover)",
						borderColor: "var(--primary-hover)",
					},
					"&:focus": {
						background: "var(--primary-hover)",
						borderColor: "var(--primary-hover)",
					},
					"&.Mui-disabled": {
						color: "var(--text-white)",
						background: "var(--primary-hover) !important",
						opacity: 0.3,
					},
				},
				textError: {
					color: "var(--text-white)",
					background: "var(--correct-incorrect-incorrect)",
					border: "2px solid var(--correct-incorrect-incorrect)",
					"&:hover": {
						background: "var(--correct-incorrect-incorrect-darker)",
						borderColor: "var(--correct-incorrect-incorrect-darker)",
					},
					"&:active": {
						background: "var(--correct-incorrect-incorrect-darker)",
						borderColor: "var(--correct-incorrect-incorrect-darker)",
					},
					"&:focus": {
						background: "var(--correct-incorrect-incorrect-darker)",
						borderColor: "var(--correct-incorrect-incorrect-darker)",
					},
					"&.Mui-disabled": {
						color: "var(--text-white)",
						background: "var(--correct-incorrect-incorrect-darker) !important",
						opacity: 0.3,
					},
				},
				textSecondary: {
					border: "2px solid var(--primary-default)",
					background: "transparent",
					color: "var(--text-default)",
					"&:hover": {
						color: "var(--primary-hover)",
						borderColor: "var(--primary-hover)",
						background: "var(--primary-outline-hover)",
					},
					"&:active": {
						color: "var(--primary-hover)",
						borderColor: "var(--primary-hover)",
						background: "var(--primary-outline-hover)",
					},
					"&:focus": {
						color: "var(--primary-hover)",
						borderColor: "var(--primary-hover)",
						background: "var(--primary-outline-hover)",
					},
					"&.Mui-disabled": {
						color: "var(--primary-hover)",
						background: "var(--primary-outline-hover) !important",
						opacity: 0.3,
					},
				},
			},
			variants: [
				{
					props: {color: "alternative"},
					style: {
						color: "var(--primary-default)",
						background: "var(--text-white)",
						border: "2px solid var(--text-white)",
						"&:hover": {
							background: "var(--primary-outline-hover)",
							borderColor: "var(--primary-outline-hover)",
						},
						"&:active": {
							background: "var(--primary-outline-hover)",
							borderColor: "var(--primary-outline-hover)",
						},
						"&:focus": {
							background: "var(--primary-outline-hover)",
							borderColor: "var(--primary-outline-hover)",
						},
						"&.Mui-disabled": {
							color: "var(--primary-hover)",
							background: "var(--text-white) !important",
							opacity: 0.3,
						},
					},
				},
			],
		},
	},
};
