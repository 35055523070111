import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {noop} from "lodash";

const checksums_url_param = new URLSearchParams(window.location.search).get("checksums");
/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 60;
const URL_LIVE_SCORING_FETCH_TIMING = Number(checksums_url_param);
const URL_LIVE_SCORING_TIMEOUT =
	(URL_LIVE_SCORING_FETCH_TIMING || LIVE_SCORING_FETCH_TIMING) * 1000;

type IChecksumAction = Record<keyof IChecksums, () => void | Promise<void>>;

export interface ILiveScoreController extends ViewController {
	subscribeLiveScoring: (location?: string) => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	protected readonly _actions: IChecksumAction;
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _isSubscribed: boolean = false;
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	get updatedChecksum(): IChecksums {
		return this._checksumStore.changedChecksums;
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this._checksumStore.fetchChecksums();
		this.callActions();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
		}, URL_LIVE_SCORING_TIMEOUT);

		const subscriptions = reaction(
			() => [this.updatedChecksum],
			() => this.callActions()
		);
		this._subscriptions$.push(subscriptions);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	callActions() {
		Object.keys(this.updatedChecksum).forEach((key) => {
			const action = this._actions[key];
			if (action && typeof action === "function") {
				void action();
			}
		});
	}

	dispose(): void {
		this.unsubscribeLiveScoring();
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(): void {
		this.subscribeLiveScoring();
	}

	protected roundsChangeAction() {
		this._roundsStore
			.fetchRounds()
			.then(() => {
				const roundId = this._roundsStore.currentRound?.id;
				if (this._userStore.isAuthorized && roundId) {
					this._predictionsStore
						.fetchPredictionsForRound(roundId)
						.catch(this._modalsStore.showErrorFromCatch);
				}
			})
			.catch(noop);
	}

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private generateActions(): IChecksumAction {
		return {
			rounds: () => this.roundsChangeAction(),
			squads: () => this._squadsStore.fetchSquads(),
		};
	}
}
