import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPredictionsApiProvider} from "data/providers/api/predictions.api.provider";

export interface IGameBarData {
	overallPoints: number | null;
	roundPoints: number | null;
}

export interface IGameBarStore {
	get isLoading(): boolean;

	get gameBarData(): IGameBarData;

	fetchGameBar(roundId: number): Promise<void>;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	@observable private _isLoading: boolean = false;
	@observable private _gameBarData: IGameBarData = {
		overallPoints: null,
		roundPoints: null,
	};

	constructor(
		@inject(Bindings.PredictionsApiProvider)
		private _predictionsProvider: IPredictionsApiProvider
	) {
		makeAutoObservable(this);
	}

	public get gameBarData(): IGameBarData {
		return this._gameBarData;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public async fetchGameBar(roundId: number): Promise<void> {
		try {
			this._isLoading = true;
			const {data} = await this._predictionsProvider.fetchGameBar(roundId);
			runInAction(() => {
				this._gameBarData = data.success.gameBar;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}
}
