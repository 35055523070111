import * as React from "react";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ILocalPrediction, IMatch, IPrediction} from "data/types/entities";
import {IMatchProps} from "data/types/props";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {DateTime} from "luxon";
import {MatchUtil} from "data/utils/match.util";

export interface IMatchCardController extends ViewController<IMatchProps> {
	onAnswer: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;

	get match(): IMatch | undefined;

	get isLive(): boolean;

	get prediction(): IPrediction | ILocalPrediction | undefined;

	get matchDate(): string;
}

@injectable()
export class MatchCardController implements IMatchCardController {
	@observable private _matchId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get match() {
		return this._roundsStore.getMatchById(this._matchId);
	}

	get prediction() {
		return this._predictionsStore.getPredictionByMatch(this._matchId);
	}

	public get matchDate(): string {
		const date = this.match?.date;
		if (!date) {
			return "";
		}

		return DateTime.fromISO(date).toFormat("h:mm a");
	}

	public get isLive(): boolean {
		return MatchUtil.IS_MATCH_LIVE(this.match);
	}

	dispose(): void {
		return;
	}

	init(param: IMatchProps): void {
		this._matchId = param.matchId;
	}

	onChange(param: IMatchProps): void {
		this._matchId = param.matchId;
	}

	public onAnswer = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
		const squadId = Number(value);
		const round = this._roundsStore.currentRound;

		if (!squadId || !round?.id || !this._matchId) {
			return;
		}
		const prediction: ILocalPrediction = {
			round: round.id,
			match: this._matchId,
			squad: squadId,
		};
		this._predictionsStore.saveLocalPrediction(prediction);
	};
}
