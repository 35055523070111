import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITutorialStep, ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";

export interface IModalTutorialController extends ViewController {
	onClose: () => void;
	goToFirstStep: () => void;
	goToStep: (currentSlide: number, nextSlide: number) => void;
	goToStepFromSteps: (currentSlide: number, nextSlide: number) => void;

	get i18n(): ILocalizationStore;

	get steps(): ITutorialStep[];

	get currentStep(): number;

	get isOpen(): boolean;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	@observable private _currentStep: number = 0;

	constructor(
		@inject(Bindings.SecretGateController) public _secretGateController: ISecretGateController,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._tutorialStore.isTutorialOpen && this._secretGateController.isSecretPassed;
	}

	public get currentStep(): number {
		return this._currentStep;
	}

	public get steps(): ITutorialStep[] {
		return this._tutorialStore.steps;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.checkModal();
	}

	onChange(param: void): void {
		return;
	}

	public onClose = (): void => {
		this._tutorialStore.closeTutorialModal();
		this._currentStep = 0;
	};

	@action
	public goToStep = (currentSlide: number, nextSlide: number): void => {
		runInAction(() => {
			this._currentStep = nextSlide;
		});
	};

	@action
	public goToStepFromSteps = (currentSlide: number, nextSlide: number): void => {
		this._currentStep = nextSlide;
	};

	@action
	public goToFirstStep = (): void => {
		this._currentStep = 1;
	};

	private checkModal(): void {
		if (!this._tutorialStore.getIsTutorialViewedStorage()) {
			this._tutorialStore.openTutorialModal();
		}
	}
}
