import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IMenuStore} from "data/stores/menu/menu.store";

export interface IModalLeaveAccountController extends ViewController {
	leave: () => void;
	stay: () => void;
	close: () => void;

	get i18n(): ILocalizationStore;

	get isBlocked(): boolean;
}

@injectable()
export class ModalLeaveAccountController implements IModalLeaveAccountController {
	constructor(
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public get isBlocked(): boolean {
		const displayName = this._userStore.user?.displayName;
		if (!this._userStore.tempName || !displayName) {
			return false;
		}
		return displayName !== this._userStore.tempName;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public close = (): void => {
		this._menuStore.closeMenu();
		this._modalsStore.hideModal();
	};

	public leave = (): void => {
		this._userStore.clearTempName();
		this.close();
	};

	public stay = (): void => {
		this.close();
	};
}
