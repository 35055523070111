import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {NavigateFunction} from "react-router-dom";
import {AxiosError} from "axios";

interface IParams {
	mmid: string | null;
	navigate: NavigateFunction;
}

export interface ISessionController extends ViewController<IParams> {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _mmid: string | null = null;
	@observable private _isSessionChecked = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	get mmid() {
		return this._mmid;
	}

	public onChange(params: IParams) {
		this._mmid = params.mmid;
		this._navigate = params.navigate;
	}

	public dispose() {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	@action init(params: IParams) {
		this._mmid = params.mmid;
		this._navigate = params.navigate;

		this.loginProcess();

		const subscription = reaction(
			() => this.mmid,
			() => this.loginProcess()
		);
		this._subscriptions$.push(subscription);

		const registerSubscription = reaction(
			() => this._userStore.isRegisterMode,
			() => this._navigate?.("/register")
		);
		this._subscriptions$.push(registerSubscription);
	}

	protected loginProcess() {
		const mmid = this.mmid;
		if (!mmid) {
			this.requestUser();
			return;
		}

		this._userStore.logout().finally(() => {
			this._userStore
				.login({mmid})
				.catch((err) => {
					this.onLoginError(err as AxiosError);
				})
				.finally(() => {
					this.sessionChecked();
					this.requestUser();
				});
		});
	}

	protected onLoginError(error: AxiosError) {
		if (error.response?.status === 401) {
			this._userStore.setRegisterMode(this.mmid);
			this._navigate?.("/register");
		}
	}

	protected requestUser() {
		void this._userStore.requestUser().then(() => {
			this.sessionChecked();
		});
	}

	protected sessionChecked() {
		runInAction(() => {
			this._isSessionChecked = true;
		});
	}
}
