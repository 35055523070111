import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {BrowserTracing, init, Integrations, Replay} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {theme} from "assets/theming/theme";
import {ThemeProvider} from "@mui/material";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {ModalTutorial} from "views/components/modals/modal_tutorial/modal_tutorial.component";
import {RouteGate} from "views/components/route/route_gate";
import {ModalReturnedUser} from "views/components/modals/modal_returned_user/modal_returned_user.component";

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", "https://nhl-predictor.jerseymikes.com/"],
		ignoreErrors: [
			"Abort due to cancellation of share",
			"AbortError: Share canceled",
			"Request failed with status code 401",
		],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	alert("Root element was not found");
	throw Error("Root element was not found");
}

try {
	DIContainer.load(services, providers, stores, controllers);

	createRoot(root).render(
		<React.StrictMode>
			<InjectionProvider container={DIContainer}>
				<ThemeProvider theme={theme}>
					<Bootstrap>
						<BrowserRouter basename={BASE_URL}>
							<Suspense fallback={<PagePreloader />}>
								<RouteGate />
							</Suspense>
						</BrowserRouter>
						<ModalError />
						<ModalConfirm />
						<ModalTutorial />
						<ModalReturnedUser />
					</Bootstrap>
				</ThemeProvider>
			</InjectionProvider>
		</React.StrictMode>
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
} catch (e) {
	alert(JSON.stringify(e));
}
