import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISponsorStore} from "data/stores/sponsor/sponsor.store";

export interface IModalPicksConfirmController extends ViewController {
	close: () => void;
	sendSponsorFlag: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;
}

@injectable()
export class ModalPicksConfirmController implements IModalPicksConfirmController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.SponsorStore) private _sponsorStore: ISponsorStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.PICKS_CONFIRM;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	sendSponsorFlag = () => {
		this._sponsorStore.sendSponsorFlag();
	};
}
