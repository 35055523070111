import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ModalType} from "data/enums";
import {getErrorMessageFromAxiosResponse} from "data/utils";
import {AxiosApiErrorGeneric} from "data/types/api";

export interface IModalPayload<T = unknown> {
	title?: string;
	message?: string;
	callback?: (payload?: unknown) => void;
	data?: T;
	translate?: boolean;
}

export type TModalArguments = [type: ModalType] | [type: ModalType, content: IModalPayload];

export interface IModalsStore {
	showErrorFromCatch: (error: unknown) => void;

	showError: (args: IModalPayload) => void;

	showModal(...args: TModalArguments): void;

	hideModal(): void;

	get modal(): ModalType | null;

	get modalContent(): IModalPayload | null;
}

@injectable()
export class ModalsStore implements IModalsStore {
	@observable _visibleModal: ModalType | null = null;
	@observable _modalContent: IModalPayload | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	get modal() {
		return this._visibleModal;
	}

	get modalContent() {
		return this._modalContent;
	}

	@action hideModal(): void {
		this._visibleModal = null;
		this._modalContent = null;
	}

	@action showModal(...args: TModalArguments): void {
		const [modalType, content = null] = args;

		this._visibleModal = modalType;
		this._modalContent = content;
	}

	@action
	public showErrorFromCatch = (args: unknown): void => {
		if (!args) {
			return;
		}

		const error = args as AxiosApiErrorGeneric;
		this.showError({
			title: "modal.error.title",
			message: getErrorMessageFromAxiosResponse(error),
			translate: false,
		});
	};

	public showError = (args: IModalPayload): void => {
		this.showModal(ModalType.ERROR, args);
	};
}
