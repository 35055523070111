import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {IGlobalPrediction, IMatch} from "data/types/entities";
import {MatchUtil} from "data/utils/match.util";
import React from "react";

interface IParams {
	containerRef: React.RefObject<HTMLDivElement>;
}

export interface IScrollButtonController extends ViewController<IParams> {
	handleClick: () => void;
	handleScroll: () => void;

	get isVisible(): boolean;
}

@injectable()
export class ScrollButtonController implements IScrollButtonController {
	@observable private _containerRef: IParams["containerRef"] | undefined;
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _isVisible: boolean = true;
	@observable private _matchElementReferences: HTMLElement[] = [];

	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	public get isVisible(): boolean {
		if (this.matches.length < 5) {
			return false;
		}
		return this._isVisible;
	}

	protected get matches(): IMatch[] {
		const matches = this._roundsStore.currentRound?.matches || [];
		const scrollableMatches = matches.slice(4);
		return scrollableMatches.filter((match) => !MatchUtil.IS_MATCH_LOCKED(match));
	}

	protected get predictions(): IGlobalPrediction[] {
		return this._predictionsStore.uniqPredictions;
	}

	public isElementInViewport(element: HTMLElement) {
		const rect = element.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
		);
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose);
	}

	init(param: IParams): void {
		this._containerRef = param.containerRef;
		this.parseMatchElements();

		const subscription = reaction(
			() => [this.matches, this.predictions],
			() => this.parseMatchElements()
		);

		this._subscriptions$.push(subscription);
	}

	onChange(param: IParams): void {
		this._containerRef = param.containerRef;
	}

	public handleClick = () => {
		const elementToScroll = this._matchElementReferences[0];
		if (!elementToScroll || !this.isVisible || !this._containerRef) {
			this._isVisible = false;
			return;
		}

		const top = elementToScroll.getBoundingClientRect().top;

		window.scroll({
			top,
			behavior: "smooth",
		});
		this._isVisible = false;
	};

	@action
	public handleScroll = () => {
		const firstEl = this._matchElementReferences[0];

		if (!firstEl) {
			return;
		}

		this._isVisible = !this.isElementInViewport(firstEl);
	};

	@observable
	private parseMatchElements() {
		if (!this.matches.length) {
			return;
		}

		this._matchElementReferences = [];

		this._matchElementReferences = this.matches
			.map((match) => document.querySelector(`#match-${match.id}:not(.predicted)`))
			.filter((element) => element !== null) as HTMLElement[];
	}
}
