import {IMatch} from "data/types/entities";
import {MatchStatus} from "data/enums";

export abstract class MatchUtil {
	public static IS_MATCH_LOCKED(match: IMatch | undefined): boolean {
		if (!match) {
			return true;
		}

		return [MatchStatus.Live, MatchStatus.Complete, MatchStatus.Postpone].includes(
			match.status
		);
	}

	public static IS_MATCH_LIVE(match: IMatch | undefined): boolean {
		if (!match) {
			return false;
		}

		return match.status === MatchStatus.Live;
	}

	public static IS_MATCH_COMPLETE(match: IMatch | undefined): boolean {
		if (!match) {
			return false;
		}

		return match.status === MatchStatus.Complete;
	}
}
