import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IMenuController} from "views/components/menu/menu.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router";

const Wrapper = styled.div`
	background: var(--bg-white);
	position: fixed;
	top: 48px;
	left: 0;
	width: 100%;
	bottom: 0;
	transform: translateX(110%);
	transition: 0.5s;
	z-index: 1000;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	&.open {
		transform: translateX(0);
	}
`;

const Link = styled(NavLink)`
	padding: 14px 0;
	width: 100%;
	border-bottom: 1px solid var(--bg-cream);

	color: var(--text-default);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;

	&.active {
		font-weight: bold;
		border-color: var(--primary-default);
	}
`;

const ButtonLink = styled.button`
	padding: 14px 0;
	width: 100%;
	background: transparent;
	border: none;
	text-align: left;
	border-bottom: 1px solid var(--bg-cream);

	color: var(--text-default);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
`;

export const Menu: React.FC = observer(() => {
	const location = useLocation();
	const {i18n, menuClass, getTabUrlByMatch, close, openTutorial} =
		useViewController<IMenuController>(Bindings.MenuController);

	useEffect(() => {
		close();
	}, [close, location.pathname]);

	return (
		<Wrapper className={menuClass}>
			<Link to="/picks">{i18n.t("header.menu.picks", "Picks")}</Link>
			<Link to="/leaderboard">{i18n.t("header.menu.leaderboards", "Leaderboards")}</Link>
			<Link to={getTabUrlByMatch("faqs")}>{i18n.t("header.menu.faqs", "FAQs")}</Link>
			<Link to={getTabUrlByMatch("terms-&-conditions")}>
				{i18n.t("header.menu.terms", "Terms & Conditions")}
			</Link>
			<Link to={getTabUrlByMatch("contact-us")}>
				{i18n.t("header.menu.contact", "Contact")}
			</Link>
			<Link to="/my-account">{i18n.t("header.menu.account", "My Account")}</Link>
			<ButtonLink onClick={openTutorial}>
				{i18n.t("header.menu.Tutorial", "Tutorial")}
			</ButtonLink>
		</Wrapper>
	);
});
