import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {isEqual, uniqBy} from "lodash";
import type {
	IGlobalPrediction,
	IGlobalPredictionEmpty,
	ILocalPrediction,
	IPrediction,
	IPredictionSaveEntity,
} from "data/types/entities";
import type {IPredictionsApiProvider} from "data/providers/api/predictions.api.provider";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {MatchUtil} from "data/utils/match.util";
import {ALLOWED_PICKS_COUNT} from "data/constants";

export interface IPredictionsStore {
	get roundPredictions(): IPrediction[];

	get localPredictions(): ILocalPrediction[];

	get uniqPredictions(): IGlobalPrediction[];

	get maximumPredictionsAmount(): number | undefined;

	getPredictionByMatchAndSquad(
		matchId: number | undefined,
		squadId: number | undefined
	): IGlobalPredictionEmpty;

	removePrediction(matchId: number | undefined, squadId: number | undefined): void;

	getPredictionByMatch(matchId: number | undefined): IGlobalPredictionEmpty;

	saveLocalPrediction(prediction: ILocalPrediction): void;

	fetchPredictionsForRound(roundId: number): Promise<void>;

	savePredictionsForRound(roundId: number): Promise<void>;

	clearPredictions(): void;

	get predictionsChanged(): boolean;
}

@injectable()
export class PredictionsStore implements IPredictionsStore {
	@observable private _roundPredictions: IPrediction[] = [];
	@observable private _roundPredictionsLocal: IPrediction[] = [];
	@observable private _localPredictions: ILocalPrediction[] = [];

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsApiProvider)
		private _predictionsProvider: IPredictionsApiProvider
	) {
		makeAutoObservable(this);
	}

	public get localPredictions(): ILocalPrediction[] {
		return this._localPredictions;
	}

	public get roundPredictions(): IPrediction[] {
		return this._roundPredictions;
	}

	public get uniqPredictions() {
		return uniqBy([...this.localPredictions, ...this.roundPredictions], "match");
	}

	public get predictionsChanged(): boolean {
		const localChanges = this.localPredictions.length > 0;

		if (localChanges) {
			return true;
		}

		if (this.roundPredictions.length === 0) {
			return false;
		}

		return !isEqual(this._roundPredictionsLocal, this._roundPredictions);
	}

	public get maximumPredictionsAmount() {
		return ALLOWED_PICKS_COUNT;
	}

	protected get predictionsForSave(): IPredictionSaveEntity {
		const predictions = uniqBy(
			[...this.localPredictions, ...this.roundPredictions],
			"match"
		).filter((prediction) => {
			const match = this._roundsStore.getMatchById(prediction.match);
			return !MatchUtil.IS_MATCH_LOCKED(match);
		});
		const entries = predictions.map((e) => [e.match, e.squad]);
		return Object.fromEntries(entries) as IPredictionSaveEntity;
	}

	@action
	public saveLocalPrediction(prediction: ILocalPrediction): void {
		const uniqFiltered = this.uniqPredictions.filter((e) => e.match !== prediction.match);
		if (uniqFiltered.length === this.maximumPredictionsAmount) {
			return;
		}

		this._localPredictions = this.localPredictions.filter((e) => e.match !== prediction.match);
		this._localPredictions.push(prediction);
	}

	public getPredictionByMatchAndSquad(matchId: number | undefined, squadId: number | undefined) {
		return this.uniqPredictions.find((e) => e.match === matchId && e.squad === squadId);
	}

	@action
	public removePrediction(matchId: number | undefined, squadId: number | undefined): void {
		this._localPredictions = this._localPredictions.filter(
			(e) => e.match !== matchId && e.squad !== squadId
		);
		this._roundPredictions = this._roundPredictions.filter(
			(e) => e.match !== matchId && e.squad !== squadId
		);
	}

	public getPredictionByMatch(matchId: number | undefined) {
		return this.uniqPredictions.find((e) => e.match === matchId);
	}

	public async fetchPredictionsForRound(roundId: number): Promise<void> {
		try {
			const {data} = await this._predictionsProvider.fetchPredictions(roundId);

			runInAction(() => {
				this._roundPredictions = data.success.predictions;
				this._roundPredictionsLocal = data.success.predictions;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async savePredictionsForRound(roundId: number): Promise<void> {
		try {
			const predictions = this.predictionsForSave;
			const {data} = await this._predictionsProvider.savePredictions(predictions, roundId);

			runInAction(() => {
				this._roundPredictions = data.success.predictions;
				this._roundPredictionsLocal = data.success.predictions;
				this._localPredictions = [];
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	public clearPredictions() {
		this._localPredictions = [];
	}
}
