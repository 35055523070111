import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {ModalCloseBlock, ModalContent} from "views/components/modals/modals.common";
import {Button, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useViewController} from "data/services/locator";
import {IModalTutorialController} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {Bindings} from "data/constants/bindings";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import {getIsIOS} from "data/utils";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import logo from "assets/img/landing/logo.png";
import {TutorialSteps} from "views/components/tutorial_steps/tutorial_steps.component";

const SliderWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.start {
		width: calc(100% - 40px);
		margin: auto auto 0 auto;
	}

	.slick-arrow {
		display: none !important;
	}
`;

const StepContent = styled.div`
	img {
		width: 100%;
		height: auto;
	}

	> div {
		padding-left: 20px;
		padding-right: 20px;
	}

	h3 {
		color: var(--primary-default);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 18px */
		text-transform: uppercase;
		margin-bottom: 12px;
		margin-top: 20px;
	}

	p {
		color: var(--text-default);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;

		span {
			color: var(--text-default, #253746);
			font-size: 16px;
			font-style: normal;
			font-weight: bold;
			line-height: 130%;
			text-transform: uppercase;
		}
	}

	> button {
		margin-top: 20px;
	}
`;

const Logo = styled.img`
	max-width: 210px;
	width: 100%;
	height: auto;
	margin: 0 auto 20px auto;
`;

export const ModalTutorial: React.FC = observer(() => {
	const sliderRef = useRef<Slider>(null);
	const {i18n, isOpen, steps, currentStep, goToFirstStep, goToStep, goToStepFromSteps, onClose} =
		useViewController<IModalTutorialController>(Bindings.ModalTutorialController);

	useEffect(() => {
		if (sliderRef?.current) {
			sliderRef?.current?.slickGoTo(currentStep);
		}
	}, [currentStep, sliderRef]);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseBlock>
					<Button sx={{minWidth: 0}} onClick={onClose}>
						<CloseIcon />
					</Button>
				</ModalCloseBlock>
				<SliderWrapper>
					<Logo src={logo} role="banner" alt="logo" />
					<Slider
						ref={sliderRef}
						adaptiveHeight={false}
						dots={false}
						infinite={false}
						speed={500}
						slidesToScroll={1}
						slidesToShow={1}
						useTransform={getIsIOS()}
						beforeChange={goToStep}>
						{steps.map((step, index) => (
							<StepContent key={index}>
								<h3
									// Disabled due html come from our BE and must be safety
									// eslint-disable-next-line
									dangerouslySetInnerHTML={{
										__html: i18n.t(step.title_loco, step.title_alt),
									}}
								/>
								<Exist when={Boolean(step.image)}>
									<img src={step.image} alt={`step-${index}`} />
								</Exist>
								<p
									// Disabled due html come from our BE and must be safety
									// eslint-disable-next-line
									dangerouslySetInnerHTML={{
										__html: i18n.t(step.text_loco, step.text_alt),
									}}
								/>

								<Exist when={currentStep === 0 && step.hasButton}>
									<Button onClick={goToFirstStep} fullWidth>
										{i18n.t("tutorial.step_1.button_next", "next")}
									</Button>
								</Exist>
								<Exist when={currentStep === steps.length - 1 && step.hasButton}>
									<Button onClick={onClose} fullWidth>
										{i18n.t("tutorial.step_6.button_play")}
									</Button>
								</Exist>
							</StepContent>
						))}
					</Slider>
					<TutorialSteps
						currentStep={currentStep}
						steps={steps}
						goToStep={goToStepFromSteps}
					/>
				</SliderWrapper>
			</ModalContent>
		</Modal>
	);
});
